<template>
  <div>
    <!-- <div class="bottom_space rounded py-2 h-full bg-white">
      <div  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 row-span-1">
        <div style="height: 100px;" class="flex shadow cursor-pointer rounded-lg bg-white  hover:shadow-2xl" @click="$router.push({ name: 'Competition' })">
          <div class="  flex rounded-l-lg  justify-center items-center w-32 bg-green-500">
          <i class="h-12 w-12 text-white fas fa-graduation-cap" ></i>
          </div>
          <p class="text-right  heading-1  self-center pl-9">
             Competitions
          </p>
        </div>
        <div  style="height: 100px;" class="flex shadow cursor-pointer rounded-lg bg-white  hover:shadow-2xl" @click="$router.push({ name: 'Users' })">
          <div class="rounded-l-lg  flex justify-center items-center w-32 bg-blue-500">
          <i class="h-12 w-12 text-white fas fa-users" ></i>
          </div>
          <p class="text-right  heading-1  self-center pl-9">
            Users
          </p>
        </div>
        <div  style="height: 100px;" class="flex shadow cursor-pointer rounded-lg bg-white  hover:shadow-2xl" @click="$router.push({ name: 'Voters' })">
          <div class="rounded-l-lg   flex justify-center items-center w-32 bg-purple-600">
          <i class="h-12 w-12 text-white fas fa-tags" ></i>
          </div>
          <p class="text-right  heading-1  self-center pl-9">
            Voters
          </p>
        </div>
        <div  style="height: 100px;" class="flex shadow cursor-pointer rounded-lg bg-white  hover:shadow-2xl" @click="$router.push({ name: 'Sponsors' })">
          <div class="rounded-l-lg   flex justify-center items-center w-32 bg-secondary">
          <i class="h-12 w-12 text-white fas fa-suitcase" ></i>
          </div>
          <p class="text-right  heading-1  self-center pl-9">
            Sponsors
          </p>
        </div>
      </div>
    </div> -->
    <div class="rounded h-full bg-white mt-20">
      <div  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 row-span-1 gap-y-20">
        <!-- <div class="relative cursor-pointer hover:shadow-xl transition delay-100" @click="$router.push({ name: 'Organizations' })">
          <div class="flex h-28 w-full rounded-lg bg-white cust_card justify-end">
            <span class="my-auto  pr-12  text-center">
              <div class="heading-2 text-text2 font-semibold">Total Campaigns</div>
            </span>
          </div>
          <div class="absolute top-0 left-6 -mt-12 h-24 w-28 rounded-md bg-blue-200 shadow-md inline-flex">
            <div class=" flex justify-center items-center w-32 ">
              <organizationSvg color="white" :width="'80px'" :height="'80px'"/>
            </div>
          </div>
        </div> -->
        <div class="relative  hover:shadow-xl transition delay-100" v-if="userDetail.isSAdmin">
           <div class="h-28 w-full rounded-lg bg-white cust_card">
             <div class="my-auto  pr-10 pt-6  text-right">
               <div class="heading-1 text-text2 font-semibold">Campaigns</div>
             </div>
             <div class="divider mt-3 mb-2"></div>
             <div class="justify-end flex heading-5 pr-10 text-primary"><span @click="$router.push({name: 'Campaigns'})" class="cursor-pointer">Campaigns</span></div>
           </div>
           <div class="absolute top-0 left-6 -mt-12 h-24 w-28 rounded-md bg-blue-200 shadow-md inline-flex">
             <div class=" flex justify-center items-center w-32 ">
               <organizationSvg color="white" :width="'70px'" :height="'70px'"/>
             </div>
           </div>
        </div>
        <div class="relative  hover:shadow-xl transition delay-100" v-if="userDetail.isSAdmin">
           <div class="h-28 w-full rounded-lg bg-white cust_card">
             <div class="my-auto  pr-10 pt-6  text-right">
               <div class="heading-1 text-text2 font-semibold">Lists</div>
             </div>
             <div class="divider mt-3 mb-2"></div>
             <div class="justify-end flex heading-5 pr-10 text-primary"><span class="cursor-pointer" @click="$router.push({name: 'Lists'})">Lists</span></div>
           </div>
           <div class="absolute top-0 left-6 -mt-12 h-24 w-28 rounded-md bg-blue-200 shadow-md inline-flex">
             <div class=" flex justify-center items-center w-32 ">
               <Listing color="white" :width="'50px'" :height="'50px'"/>
             </div>
           </div>
        </div>
        <div class="relative  hover:shadow-xl transition delay-100" >
           <div class="h-28 w-full rounded-lg bg-white cust_card">
             <div class="my-auto  pr-10 pt-6  text-right">
               <div class="heading-1 text-text2 font-semibold">Leads</div>
             </div>
             <div class="divider mt-3 mb-2"></div>
             <div class="justify-end flex heading-5 pr-10 text-primary"><span class="cursor-pointer" @click="$router.push({name: 'Leads'})">Leads</span></div>
           </div>
           <div class="absolute top-0 left-6 -mt-12 h-24 w-28 rounded-md bg-blue-200 shadow-md inline-flex">
             <div class=" flex justify-center items-center w-32 ">
               <customerSvg color="white" :width="'70px'" :height="'70px'"/>
             </div>
           </div>
        </div>
      </div>
  </div>
</div>
</template>
<script>
import customerSvg from '@/assets/images/svg/customerSvg.vue'
import organizationSvg from '@/assets/images/svg/organizationSvg.vue';
import Listing from '@/assets/images/svg/listing.vue';
// import customerSvg from '@/assets/images/svg/customerSvg.vue';
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    customerSvg,
    Listing,
    organizationSvg
  },
  data () {
    return {
      userDetail: {},
    }
  },
  created() {
  },
  mounted () {
    this.userDetail = JSON.parse(localStorage.getItem('employeeData1'))
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
