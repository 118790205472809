<template>
<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg :width="width" :height="height" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	viewBox="0 0 512 512" xml:space="preserve">
<path style="fill:#00A8E1;" d="M484.231,36.24c0-15.467-12.655-28.123-28.123-28.123H55.892c-15.467,0-28.123,12.656-28.123,28.123
	V475.76c0,15.467,12.655,28.123,28.123,28.123h400.216c15.468,0,28.123-12.656,28.123-28.123L484.231,36.24L484.231,36.24z"/>
<g>
	<circle style="fill:#FFFFFF;" cx="124.684" cy="97.791" r="56.091"/>
	<circle style="fill:#FFFFFF;" cx="124.684" cy="257.918" r="56.091"/>
	<circle style="fill:#FFFFFF;" cx="124.684" cy="418.066" r="56.091"/>
</g>
<path style="fill:#1E252B;" d="M206.427,285.538h191.234c4.483,0,8.117-3.634,8.117-8.117s-3.634-8.117-8.117-8.117H206.427
	c-4.483,0-8.117,3.634-8.117,8.117S201.945,285.538,206.427,285.538z M397.661,429.444H206.427c-4.483,0-8.117,3.634-8.117,8.117
	s3.634,8.117,8.117,8.117h191.234c4.483,0,8.117-3.634,8.117-8.117S402.144,429.444,397.661,429.444z M443.407,230.308h-236.98
	c-4.483,0-8.117,3.634-8.117,8.117c0,4.483,3.634,8.117,8.117,8.117h236.98c4.483,0,8.117-3.634,8.117-8.117
	C451.524,233.942,447.89,230.308,443.407,230.308z M443.407,390.446h-236.98c-4.483,0-8.117,3.634-8.117,8.117
	c0,4.483,3.634,8.117,8.117,8.117h236.98c4.483,0,8.117-3.634,8.117-8.117C451.524,394.08,447.89,390.446,443.407,390.446z
	M456.108,0H55.892c-19.982,0-36.24,16.256-36.24,36.24V475.76c0,19.982,16.256,36.24,36.24,36.24h400.216
	c19.982,0,36.24-16.257,36.24-36.24V36.24C492.348,16.256,476.09,0,456.108,0z M476.114,475.76c0,11.031-8.974,20.006-20.006,20.006
	H55.892c-11.031,0-20.006-8.975-20.006-20.006V36.24c0-11.032,8.975-20.006,20.006-20.006h400.216
	c11.031,0,20.006,8.974,20.006,20.006L476.114,475.76L476.114,475.76z M206.427,125.4h191.234c4.483,0,8.117-3.634,8.117-8.117
	c0-4.483-3.634-8.117-8.117-8.117H206.427c-4.483,0-8.117,3.634-8.117,8.117C198.31,121.766,201.945,125.4,206.427,125.4z
	M443.407,70.17h-236.98c-4.483,0-8.117,3.634-8.117,8.117s3.634,8.117,8.117,8.117h236.98c4.483,0,8.117-3.634,8.117-8.117
	C451.524,73.804,447.89,70.17,443.407,70.17z"/>
</svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: '#0f0101'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
